<template>
    <div class="draw" :class="drawStatus" :data-night="night">
        <div v-if="hasData" class="content">

            <div class="toggle" :class="drawStatus" @click="toggleDraw();">
                <i class="fas fa-upload"></i>
            </div>

            <div class="project" v-for="content in api" :key="content.id">
                <img class="bw-image" @click="getProject(content.id); toggleDraw(); openModal();" :src="content.thumbnail" :alt="content.title">
                <div class="title" @click="getProject(content.id); toggleDraw(); openModal();" v-html="content.title"></div>
            </div>
            
        </div>
        <Loader v-else />
    </div>

    <div class="modal" :data-night="night" v-if="modalStatus === 'modal-open' || modalStatus === 'modal-closing' && hasData" :class="[pageStatus, drawStatus, modalStatus]">
        <div v-if="hasProjectData" class="content">
            <div class="close" @click="closeModal(); toggleDraw();">
                &Cross;
            </div>
            <div class="description">
                
                <h3 v-html="title"></h3>
                <div v-html="description"></div>

                <div class="block-image" v-for="(image, index) in images" :key="index">
                    <img @click="viewImage(index)" :src="image" :alt="index">
                </div>

                <div class="target-image" 
                    :class="[imageNum === index ? 'viewing' : 'not-viewing']" 
                    v-for="(image, index) in images" :key="index" 
                    @click="viewImage(index)"
                >

                    <img :src="image" :alt="title">
                </div>
            </div>

            <div class="details">
                <div v-html="details"></div>
                <a v-if="url" class="visit" target="_blank" :href="url">Visit site</a>
            </div>
        </div>
        <Loader v-else />

        <div @click="closeImage()" :class="[viewing === true ? 'viewing' : 'not-viewing']" class="backdrop"></div>
    </div>

</template>

<script>
    import Loader from '@/components/DataLoader.vue'

    export default {
        name: "Draw",

        components: {
            Loader
        },

        props: {
            pageStatus: String,
            endpoint: String
        },

        data: () => ({
            api: Array,
            description: String,
            details: String,
            images: Array,
            title: String,
            url: String,
            drawStatus: String,
            modalStatus: String,
            imageNum: Number,
            hasData: false,
            hasProjectData: false,
            night: false,
            viewing: false
        }),

        created () {
            this.getContent()
            this.drawStatus = this.$store.getters.drawStatus
            this.modalStatus = this.$store.getters.modalStatus
            this.night = this.$store.getters.night
        },

        computed: {
            returnApi () {
                return this.$store.getters.apiContent
            },
            getCurrentId () {
                return this.$store.getters.getRequested
            },
            getPageStatus () {
                return this.$store.getters.pageStatus
            },
            getDrawStatus () {
                return this.$store.getters.drawStatus
            },
            getNight () {
                return this.$store.getters.night
            },
            getModalStatus () {
                return this.$store.getters.modalStatus
            },
            getNav () {
                return this.$store.getters.navStatus
            }
        },

        watch: {
            $route () {
                this.$store.commit('updateDraw', 'draw-open')
                this.$store.commit('updateModal', 'modal-closed')
            },
            returnApi: function(data) {
                if(data) {
                    this.api = data
                    this.hasData = true
                }
            },
            getCurrentId: function(data) {
                if(data[0] && data[0].description && data[0].details) {
                    this.description = data[0].description
                    this.details     = data[0].details
                    this.images      = JSON.parse(data[0].images)
                    this.title       = '&GT; ' + data[0].title
                    this.url         = data[0].url
                    this.hasProjectData     = true
                }
            },
            getPageStatus: function() {
                // Check page status and close draw when the page is navigating away
                this.drawStatus = 'draw-closed'
            },
            getDrawStatus: function(status) {
                this.drawStatus = status
            },
            getModalStatus: function(status) {
                this.modalStatus = status
            },
            getNav: function(status) {
                if(status == 'open') {
                    this.$store.commit('updateDraw', 'draw-closed')
                }
            },
            getNight: function(status) {
                this.night = status
            }
        },

        methods: {
            getContent () {
                this.$store.dispatch('getContent', this.endpoint)
            },
            getProject(id) {
                this.$store.commit('setRequested', id)
            },
            toggleDraw () {
                const status = this.$store.getters.drawStatus;
                if(status == 'draw-open') {
                    this.closeDraw()
                } else if(status == 'draw-closed') {
                    this.openDraw()
                } else {
                    this.closeDraw()
                }
            },
            openDraw () {
                this.$store.commit('updateDraw', 'draw-open')
            },
            closeDraw () {
                this.$store.commit('updateDraw', 'draw-closed')
            },
            openModal () {
                this.$store.commit('updateModal', 'modal-open')
            },
            closeModal () {
                this.modalStatus = 'modal-closing'
                setTimeout( () => this.$store.commit('updateModal', 'modal-closed'), 200)
            },
            viewImage (index) {
                if(this.imageNum != index) {
                    this.imageNum = index
                    this.viewing = true
                } else {
                    this.closeImage()
                }
            },
            closeImage() {
                this.viewing = false
                this.imageNum = null
            }
        }
    }
</script>

<style lang="scss">

        .draw {
            width: 300px;
            height: 100%;
            padding: 0;
            display: flex;
            align-items: center;
            position: fixed;
            top: 0;
            right: calc(-300px + 20px);
            background: rgba(93,118,127,0.85);
            color: $off-white;
            animation: drawOpen300 1s ease-in-out;
            animation-fill-mode: forwards;
            z-index: 3;

            @media screen and (min-width: 768px) {
                width: 500px;
                padding: 0;
                right: calc(-500px + 20px);
                animation: drawOpen500 1s ease-in-out;
                animation-fill-mode: forwards;
            }

            &[data-night="true"] {
                background: rgba(33, 36, 43, 0.97);

                .content {
                    .toggle {
                        background: rgba(67, 74, 89, 0.97);
                        border: 1px solid #212024;
                    }
                }
            }

            &.draw-open {
                animation: drawOpen300 .6s ease;
                animation-fill-mode: forwards;

                @media screen and (min-width: 768px) {
                    animation: drawOpen500 .6s ease;
                    animation-fill-mode: forwards;
                }
            }

            &.draw-closed {
                animation: drawClosed300 .3s ease;
                animation-fill-mode: forwards;

                @media screen and (min-width: 768px) {
                    animation: drawClosed500 .6s ease;
                    animation-fill-mode: forwards;
                }
            }

            /// See mixins for params
            @include drawAnimation(500);
            @include drawAnimation(300);

            .content {
                height: 100%;
                position: relative;
                display: flex;
                flex-wrap: wrap;
                flex-direction: row;
                align-items: center;
                align-content: center;
                padding: 15px;
                color: $white;

                @media screen and (min-width: 768px) {
                    flex-direction: row;
                    padding: 15px;
                }

                .project {
                    position: relative;
                    width: calc(50% - 10px);
                    margin: 5px 5px 0;
                    overflow: hidden;
                    object-fit: cover;

                    img {
                        cursor: pointer;
                    }

                    &:hover img {
                        transform: scale(1.2) rotate(-5deg);
                        transition: all 0.5s cubic-bezier(.25,.8,.25,1);
                    }

                    .title {
                        display: none;
                        width: 100%;
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        padding: 20px 0 5px 10px;
                        background: linear-gradient(180deg, rgba(0,0,0,0) 0%,rgba(0,0,0, .2) 20%,rgba(0,0,0, .6) 50%, #000 100%);
                        cursor: pointer;

                        @media screen and (min-width: 768px) {
                            display: block;
                        }
                    }
                }

                .toggle {
                    width: 50px;
                    height: 50px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    position: absolute;
                    top: 30px;
                    left: -58px;
                    font-size: 1.6rem;
                    line-height: 0;
                    cursor: pointer;
                    text-shadow: 1px 1px 8px $dark-sky;
                    background: rgb(93,118,127);
                    color: rgb(210, 224, 230);
                    border-radius: 50%;

                    i {
                        position: relative;
                        top: -2px;
                    }

                    @media screen and (min-width: 576px) {
                        left: -58px;
                        top: 50%;
                        transform: translateY(-50%);
                    }

                    &.draw-open {
                        transform: rotate(90deg);
                        transition: all .6s ease;
                        background: $grass;
                        border: 1px solid #647960;
                        left: 10px;

                        @media screen and (min-width: 576px) {
                            left: -58px;
                        }
                    }

                    &.draw-closed {
                        transform: rotate(-90deg);
                        transition: all .3s ease;
                    }
                }
            }
        }

    .modal {
        background: rgba(93,118,127,0.9);
        background: linear-gradient(180deg, rgba(39,51,56,0.9) 0%, rgba(93,118,127,0.9) 100%);
        color: $off-white;
        @include shadow-5();
        width: 82%;
        max-width: 1200px;
        padding: 5%;
        position: absolute;
        top: 100px;
        left: 0;
        right: auto;
        margin: 0;
        transition: all .4s ease;
        z-index: 2;

        @media screen and (min-width: 768px) {
            left: 0;
            right: auto;
            margin: 0;
        }

        @media screen and (min-width: 1200px) {
            left: 0;
            right: 0;
            margin: 0 auto;
        }

        &[data-night="true"] {
            background: rgba(59, 65, 78,0.9);
            background: linear-gradient(180deg, rgba(25, 28, 34, 0.9) 0%, rgba(59, 65, 78,0.9) 100%);
        }

        &.draw-open {
            left: -300px;
            transition: all .6s ease;

            @media screen and (min-width: 768px) {
                left: -500px;
            }

            @media screen and (min-width: 1200px) {
                left: -1000px;
            }
        }

        &.draw-closed {
            left: 0;
            right: auto;
            margin: 0;
            transition: all .3s ease;

            @media screen and (min-width: 768px) {
                left: 15px;
                transition: all .6s ease;
            }

            @media screen and (min-width: 1200px) {
                left: 0;
                right: 0;
                margin: 0 auto;
            }
        }

        &.page-open {
            animation: modalOpen .6s ease;
            animation-fill-mode: forwards;
        }

        &.page-closed,
        &.modal-closing {
            animation: modalClosed .3s ease;
            animation-fill-mode: forwards;
        }

        .block-image {
            width: calc(50% - 20px);
            height: 120px;
            display: inline-block;
            margin: 10px;
            overflow: hidden;
            cursor: pointer;
            transition: all .4s ease;

            @media screen and (min-width: 576px) {
                max-width: calc(25% - 20px);
                height: 100px;
            }

            @media screen and (min-width: 991px) {
                max-width: calc(25% - 20px);
                height: 100px;
            }

            img {
                transition: all 2s cubic-bezier(.25,.8,.25,1);
                filter: grayscale(100%);
            }

            &:not(.not-viewing) {
                img {
                    filter: grayscale(0);
                }
            }

            &:not(.viewing) {
                img {
                    max-width: none;
                    width: 135%;
                }
            }

            &:hover:not(.viewing) {
                img {
                    transform: rotate(-10deg) scale(1.2);
                    filter: grayscale(0);
                    transition: all 0.5s cubic-bezier(.25,.8,.25,1);
                }
            }

            &.not-viewing {
                transform: scale(1);
            }
        }

        .target-image {
            opacity: 0;
            visibility: hidden;
            display: flex;
            justify-content: center;
            align-items: flex-start;
            max-width: 800px;
            max-height: 90%;
            position: fixed;
            top: 54%;
            transform: translateY(-50%);
            left: 0;
            right: 0;
            margin: 0 auto;
            z-index: 7;

            &.viewing {
                    opacity: 0;
                    animation: showImageMob 1s ease;
                    animation-fill-mode: forwards;
                    animation-delay: .2s;

                    @media screen and (min-width: 1000px) {
                        animation: showImage 1s ease;
                        animation-fill-mode: forwards;
                        animation-delay: .2s;
                    }
            }

            @keyframes showImage {
                0% {
                    opacity: 0;
                    visibility: hidden;
                    max-width: 800px;
                    max-height: 90%;
                    position: fixed;
                    top: 54%;
                    transform: translateY(-50%);
                    left: 0;
                    right: 0;
                    margin: 0 auto;
                    z-index: 7;
                }

                100% {
                    opacity: 1;
                    visibility: visible;
                    max-width: 800px;
                    max-height: 90%;
                    position: fixed;
                    top: 50%;
                    transform: translateY(-50%);
                    left: 0;
                    right: 0;
                    margin: 0 auto;
                    z-index: 7;
                }
            }

            @keyframes showImageMob {
                0% {
                    opacity: 0;
                    visibility: hidden;
                }

                100% {
                    opacity: 1;
                    visibility: visible;
                    max-height: 90%;
                    position: fixed;
                    top: 50%;
                    transform: translateY(-50%);
                    left: 0;
                    right: 0;
                    margin: 0 auto;
                    z-index: 7;
                }
            }
        }

        .content {
            position: relative;
            display: flex;
            flex-direction: column;
            align-self: center;
            padding-bottom: 40px;

            @media screen and (min-width: 768px) {
                flex-direction: row;
                padding: 0;
            }

            .close {
                position: absolute;
                top: 10px;
                right: 0;
                font-size: 4rem;
                line-height: 0;
                cursor: pointer;

                @media screen and (min-width: 768px) {
                    top: -5px;
                    right: -25px;
                }
            }

            a.visit {
                padding-left: 40px;
            }

            blockquote {
                background: $sea;
                padding: 15px;
                margin: 0 0 30px;
                font-style: italic;
                background: rgba(25, 28, 34, 0.4);

                p {
                    margin-top: 0;

                    &:last-of-type {
                        margin-bottom: 0;
                    }
                }
            }
        }

        h3 {
            font-size: 2.3rem;
            font-weight: 400;
            margin: 0 0 15px;
        }

        .description {
            width: 100%;
            margin-right: 30px;

            @media screen and (min-width: 768px) {
                width: calc(60% - 30px);
            }
        }

        .details {
            margin-top: 20px;

            @media screen and (min-width: 768px) {
                margin-top: 0;
            }
        }
    }

    @keyframes modalOpen {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }

    @keyframes modalClosed {
        from {
            opacity: 1;
        }
        to {
            opacity: 0;
        }
    }

    .backdrop {
        opacity: 0;

        &.viewing,
        &.not-viewing {
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: rgba(0, 0, 0, .6);
            z-index: 3;
        }

        &.viewing {
            opacity: 1;
            visibility: visible;
            transition: all 1s ease;
        }

        &.not-viewing {
            opacity: 0;
            visibility: hidden;
            transition: all .6s ease;
        }
    }
</style>